





























import { Component, Prop, Mixins, Ref } from 'vue-property-decorator'

// components
import Confirmation from '@/components/modals/Confirmation.vue'
import ManagerLessonCard from '@/components/cards/ManagerLessonCard.vue'
import LessonModal from '@/components/modals/lessons/LessonModal.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
// store
import ManagerCoursesModule from '@/store/modules/manager/courses'
// types
import { LessonShortResource, ProgramMonthLargeResource } from '@/store/types'

@Component({
  components: {
    Confirmation,
    LessonModal,
    ManagerLessonCard,
  },
})
export default class CoursesItemProgramMonthLessons extends Mixins(NotifyMixin, PermissionsMixin) {
  @Ref() confirm!: Confirmation

  @Prop({ required: true })
  private month!: ProgramMonthLargeResource

  private showLessonModal = false
  private selectedLessonID: number | null = null

  private get courseID () {
    return +this.$route.params.courseID
  }

  private get monthID () {
    return +this.$route.params.monthID
  }

  private get lessons () {
    return ManagerCoursesModule.lessons
  }

  private get lessonsID(): number[] {
    if (this.lessons) {
      return this.lessons.map(lesson => lesson.id)
    }
    return []
  }

  private mounted () {
    this.fetchLessons()
  }

  private fetchLessons () {
    ManagerCoursesModule.fetchLessons({
      courseID: this.courseID,
      monthID: this.monthID,
    })
      .catch(this.notifyError)
  }

  private handleSelectLesson (lesson: LessonShortResource) {
    this.selectedLessonID = lesson.id
    this.showLessonModal = true
  }

  private handleEditLesson (lesson: LessonShortResource) {
    this.$router.push({
      name: 'manager.control.courses.item.program.month.lessons.item.edit',
      params: {
        courseID: this.month.course.id.toString(),
        lessonID: lesson.id.toString(),
        monthID: this.month.id.toString(),
      },
    })
  }

  private handleDeleteLesson (lesson: LessonShortResource) {
    ManagerCoursesModule.deleteLesson({
      courseID: this.month.course.id,
      lessonID: lesson.id,
      monthID: this.month.id,
    })
      .then(() => {
        this.notifySuccess('Урок удален')
        this.fetchLessons()
      })
      .catch(this.notifyError)
  }

  private confirmDeleteLesson (lesson: LessonShortResource) {
    this.confirm.open(
      'Удаление урока',
      'Вы уверены, что хотите удалить урок?',
      {
        buttonConfirmText: 'Удалить',
      },
    )
      .then(() => {
        this.handleDeleteLesson(lesson)
      })
      .catch(() => {return})
  }

  private changeLesson(id: number) {
    this.selectedLessonID = id
  }
}
