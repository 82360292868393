


















































import { Component, Prop, Vue } from 'vue-property-decorator'

import Tag from '@/components/_uikit/Tag.vue'
import { LessonShortResource } from '@/store/types'
import AuthModule from '@/store/modules/auth'

@Component({
  components: {
    Tag,
  },
})
export default class ManagerLessonCard extends Vue {
  @Prop({ required: true })
  private lesson!: LessonShortResource

  @Prop({ required: true })
  private index!: number

  @Prop({ default: false })
  private readonly!: boolean

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private get broadcastUrlIsVK() {
    return !!this.lesson.broadcastUrl?.match(/vk.com|vkvideo.ru/ig)?.length
  }

  private get videoUrlIsVK() {
    return !!this.lesson.videoUrl?.match(/vk.com|vkvideo.ru/ig)?.length
  }
}
